
import {
  AnalyticsState,
  AnalyticsTypes,
  DashboardFilter,
} from "@/store/modules/analytics/analytics.types";
import { FilterType, FilterValue } from "@/types/types";
import VueApexCharts from "vue-apexcharts";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Analytics = namespace("Analytics");

@Component({
  components: {
    StatsAvatar: () => import("@/components/dashboard/StatsAvatar.vue"),
    VueApexCharts,
  },
})
export default class CategoryStats extends Vue {
  @Analytics.State(AnalyticsTypes.DASHBOARD_CATEGORIES)
  public dashboardCategories!: AnalyticsState;

  @Analytics.Getter(AnalyticsTypes.CURRENT_DASHBOARD_FILTER)
  public currentDashboardFilter!: FilterType;

  @Analytics.Action(AnalyticsTypes.LOAD_DASHBOARD_CATEGORIES)
  public loadDashboardCategoryStats!: (filter: DashboardFilter) => void;

  get series(): unknown {
    return [
      {
        name: "Tickets",
        data: this.dashboardCategories.analytics.map((a) => ({
          x: a.name,
          y: a.count,
        })),
      },
    ];
  }

  public options: unknown = {
    stroke: {
      curve: "smooth",
    },
    colors: ["#AA7EFC"],
    grid: {
      show: true,
      borderColor: "#EEEEEE",
      strokeDashArray: 10,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      title: {
        text: "Categories",
        offsetY: 90,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: ["#757575"],
        },
        formatter: (value: number) => value.toFixed(0),
      },
      title: {
        text: "Tickets",
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
  };

  public filterValue: FilterValue = {};

  filterChange(value: FilterValue): void {
    this.filterValue = value;

    this.loadDashboardCategoryStats({
      filterBy: value.filterValue?.value,
      force: value.forced,
    });
  }

  get period(): string {
    return this.currentDashboardFilter.value.toLocaleLowerCase();
  }

  mounted(): void {
    this.filterValue.filterValue = this.currentDashboardFilter;
    this.filterChange(this.filterValue);
  }
}
